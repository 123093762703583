export enum ETipoInstrumento {
  COMPUTADOR_VAZAO = 'COMPUTADOR_VAZAO',
  ELEMENTO_TEMPERATURA = 'ELEMENTO_TEMPERATURA',
  MANOMETRO = 'MANOMETRO',
  MEDIDOR_VAZAO_CORIOLIS = 'CORIOLIS',
  MEDIDOR_VAZAO_ROTATIVO = 'ROTATIVO',
  MVS = 'MVS',
  PLACA_ORIFICIO = 'PLACA_ORIFICIO',
  REGUA = 'REGUA',
  TANQUE = 'TANQUE',
  TERMOMETRO = 'TERMOMETRO',
  TERMOMETRO_COSTADO_TANQUE = 'TERMOMETRO_COSTADO_TANQUE',
  TERMOMETRO_IMERSAO_TANQUE = 'TERMOMETRO_IMERSAO_TANQUE',
  TRANSMISSOR_DE_NIVEL = 'TRANSMISSOR_DE_NIVEL',
  TRANSMISSOR_DIFERENCIAL_PRESSAO = 'TRANSMISSOR_DIFERENCIAL_PRESSAO',
  TRANSMISSOR_PRESSAO = 'TRANSMISSOR_PRESSAO',
  TRANSMISSOR_TEMPERATURA = 'TRANSMISSOR_TEMPERATURA',
  TRECHO_RETO = 'TRECHO_RETO',
  TRENA = 'TRENA',
  TURBIDIMETRO = 'TURBIDIMETRO',
  VALVULA = 'VALVULA',
  ULTRASSONICO = 'ULTRASSONICO'
}
